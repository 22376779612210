<template>
  <div class="notice">
    <div class="settingwarp">
      <header>
        <div class="g-header" style="background: rgb(14, 21, 48)">
          <div class="left" @click="changego">
            <van-icon name="arrow-left" color="#333" size="20px" />
          </div>
          <div class="middle fs-18">{{ $t("xiaoxigonggao") }}</div>
          <div class="right">
            <div class="bg-icon bg-icon-setting"></div>
          </div>
        </div>
        <div class="blockHeight"></div>
      </header>
      <div class="warpnotice">
        <div class="newdesc" v-for="item in infoList" :key="item.id">
          <p @click="gamexclick(item.id)">{{ item.title }}</p>
        </div>
      </div>
      <div class="gamex" v-show="gamexshow">
        <div class="topx">
          <div class="top_left" @click="gamefalse">
            <img class="left_img" src="@/assets/image/thisleftx.png" />
          </div>
          <p class="top_cent" v-html="gatsinfo.title"></p>
        </div>
        <div class="cont_desc" style="overflow-y: auto; height: calc(100vh - 110px);">
          <p class="cont_p" v-html="gatsinfo.content">
          </p>
        </div>
      </div>
      <!-- <div class="g-content">
        <van-tabs v-model="active" animated swipeable>
          <van-tab>
            <template #title>{{ $t("zhanneixiaoxi") }} </template>
          </van-tab>
          <van-tab>
            <template #title>{{ $t("pingtaigonggao") }} </template>

            <div class="warpnotice">
              <div style="margin-top: 3.2rem">{{ $t("wuTps") }}</div>
            </div>
          </van-tab>
        </van-tabs>
      </div> -->
    </div>
  </div>
</template>
<script>
import homeApi from "@/api/home";
export default {
  name: "Notice",
  components: {},
  data() {
    return {
      active: 1,
      info: "",
      infoList: [],
      gamexshow: false,
      gatsinfo: [],
      issue: ''
    };
  },
  methods: {
    changego() {
      this.$router.go(-1);
    },
    gamexclick(id) {
      this.gamexshow = true
      console.log(id)
      homeApi
        .articledetail(id)
        .then((data) => {
          console.log(data.data, "data.data");
          this.gatsinfo = data.data;
          this.$toast.clear();
        })
        .catch((err) => {
          console.log(err, "data.data");
          // this.$toast.clear();
          this.$toast.fail(err.data);
        });
    },
    gamefalse() {
      this.gamexshow = false;
    },
    infos() {
      homeApi
        .articleList()
        .then((data) => {
          console.log(data.data, "data.data");
          this.infoList = data.data;
          this.$toast.clear();
        })
        .catch((err) => {
          // alert(err)
          // this.$toast.clear();
          this.$toast.fail(err);
        });
    },
  },
  mounted() {
    this.infos();
  },
};
</script>
<style lang="less" scoped>
.settingwarp {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.warpnotice {
  margin-top: 20px;
  height: 100%;
  .newdesc {
    margin: 0px auto 10px;
    border-radius: 8px;
    text-align: left;
    padding: 0px 20px;
    line-height: 60px;
    box-sizing: border-box;
    width: 90%;
    height: 60px;
    background: rgba(0, 0, 0, 0.4);
    font-size: 16px;
  }
}
.gamex {
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2051;
  color: #333;
  .topx {
    height: 50px;
    line-height: 50px;
    background: #f5f5f5;

    .left_img {
      width: 25px;
      height: 25px;
      float: left;
      margin: 13px -15px 0px 15px;
    }
    .top_cent {
      font-size: 18px;
    }
  }
  .cont_desc {
    padding: 10px 15px;
    .cont_p {
      text-align: left;
      font-size: 16px;
      line-height: 30px;
    }
  }
}
</style>
